export let data =  [
    {
      title: '前端学习文档',
      ref: 'box1',
      data: [
        {
          icon: require('../assets/icons/html.png'),
          href: 'https://www.runoob.com/html/html-tutorial.html',
          text: 'HTML',
          desc: 'HTML 是通向 WEB 技术世界的钥匙',
        },
        {
          icon: require('../assets/icons/css.png'),
          href: 'https://www.runoob.com/css/css-tutorial.html',
          text: 'CSS',
          desc: 'CSS 是一种描述 HTML 文档样式的语言',
        },
        {
          icon: require('../assets/icons/javascript.png'),
          href: 'https://www.runoob.com/js/js-tutorial.html',
          text: 'Javascript',
          desc: 'JavaScript 是属于 HTML 和 Web 的编程语言',
        },

        {
          icon: require('../assets/icons/jQuery.png'),
          href: 'https://www.runoob.com/jquery/jquery-tutorial.html',
          text: 'jQuery',
          desc:
            'jQuery 是一个 JavaScript 库。jQuery 极大地简化了 JavaScript 编程。',
        },

        {
          icon: require('../assets/icons/ajax.png'),
          href: 'https://www.w3school.com.cn/js/js_ajax_intro.asp',
          text: 'Ajax',
          desc:
            'AJAX 是一种在无需重新加载整个网页的情况下，能够更新部分网页的技术。',
        },

        {
          icon: require('../assets/icons/Bootstrap.png'),
          href: 'https://v4.bootcss.com/docs/getting-started/introduction/',
          text: 'Bootstrap',
          desc:
            'Bootstrap 是全球最受欢迎的前端框架，用于构建响应式、移动设备优先的网站。',
        },

        {
          icon: require('../assets/icons/ES6.png'),
          href: 'https://es6.ruanyifeng.com/#docs/intro',
          text: 'ES6',
          desc:
            'ECMAScript 6.0（以下简称 ES6）是 JavaScript 语言的下一代标准，已经在 2015 年 6 月正式发布了。',
        },

        {
          icon: require('../assets/icons/git.png'),
          href: 'https://www.liaoxuefeng.com/wiki/896043488029600',
          text: 'Git',
          desc: 'Git是目前世界上最先进的分布式版本控制系统（没有之一）',
        },

        {
          icon: require('../assets/icons/Webpack.png'),
          href: 'https://www.webpackjs.com/',
          text: 'Webpack',
          desc:
            '本质上，webpack 是一个现代 JavaScript 应用程序的静态模块打包器(module bundler)。',
        },

        {
          icon: require('../assets/icons/less.png'),
          href: 'https://less.bootcss.com/#%E6%A6%82%E8%A7%88',
          text: 'Less',
          desc:
            'Less （Leaner Style Sheets 的缩写） 是一门向后兼容的 CSS 扩展语言。',
        },

        {
          icon: require('../assets/icons/Sass.png'),
          href: 'https://www.sass.hk/',
          text: 'Sass/Scss',
          desc: '世界上最成熟、最稳定、最强大的专业级CSS扩展语言！',
        },

        {
          icon: require('../assets/icons/Vue.png'),
          href: 'https://v2.cn.vuejs.org/',
          text: 'Vue',
          desc: 'Vue是一套用于构建用户界面的渐进式框架。',
        },

        {
          icon: require('../assets/icons/mini-program.png'),
          href:
            'https://developers.weixin.qq.com/miniprogram/dev/framework/quickstart/',
          text: '微信小程序',
          desc:
            '小程序是一种全新的连接用户与服务的方式，它可以在微信内被便捷地获取和传播，同时具有出色的使用体验。',
        },

        {
          icon: require('../assets/icons/uniapp.png'),
          href: 'https://uniapp.dcloud.io/',
          text: 'Uniapp',
          desc:
            'uni-app 是一个使用 Vue.js (opens new window)开发所有前端应用的框架，开发者编写一套代码，可发布到iOS、Android、Web（响应式）、以及各种小程序（微信/支付宝/百度/头条/飞书/QQ/快手/钉钉/淘宝）、快应用等多个平台。',
        },

        {
          icon: require('../assets/icons/typescript.png'),
          href: 'https://www.tslang.cn/docs/home.html',
          text: 'TypeScript',
          desc:
            'TypeScript是JavaScript类型的超集，它可以编译成纯JavaScript。',
        },

        {
          icon: require('../assets/icons/react.png'),
          href: 'https://react.docschina.org/docs/getting-started.html',
          text: 'React',
          desc: 'React 是一个用于构建用户界面的 JavaScript 库。',
        },

        {
          icon: require('../assets/icons/node.png'),
          href: 'http://nodejs.cn/',
          text: 'Node.js',
          desc:
            'Node.js® 是一个基于 Chrome V8 引擎 的 JavaScript 运行时环境。',
        },
        {
          icon: require('../assets/icons/M.png'),
          href: 'https://developer.mozilla.org/zh-CN//',
          text: 'Mozilla(文档集合)',
          desc: '技术开发文档',
        },
      ],
    },
    {
      title: '常用的库',
      ref: 'box2',
      data: [
        {
          icon: require('../assets/icons/vant.png'),
          href: 'https://vant-contrib.gitee.io/vant/v2/#/zh-CN/',
          text: 'Vant',
          desc: 'Vant 是一个轻量、可靠的移动端组件库，于 2017 年开源。',
        },
        {
          icon: require('../assets/icons/Element-UI.png'),
          href: 'https://element.eleme.cn/#/zh-CN/component/installation',
          text: 'Element-ui',
          desc:
            '网站快速成型工具 Element,一套为开发者、设计师和产品经理准备的的桌面端组件库',
        },
        {
          icon: require('../assets/icons/uview.png'),
          href: 'https://www.uviewui.com/components/intro.html',
          text: 'uView-ui(Uniapp)',
          desc:
            'uView是uni-app生态专用的UI框架，uni-app 是一个使用 Vue.js 开发所有前端应用的框架，',
        },
        {
          icon: require('../assets/icons/Echarts.png'),
          href: 'https://echarts.apache.org/handbook/zh/get-started/',
          text: 'ECharts(图表)',
          desc: 'ECharts一个基于 JavaScript 的开源可视化图表库',
        },
        {
          icon: require('../assets/icons/cool.png'),
          href: 'https://docs.cool-js.com/',
          text: 'UNI-APP组件库',
          desc: '全端全套解决方案，涵盖服务端，桌面端',
        },
      ],
    },
    {
      title: '代码托管平台',
      ref: 'box3',
      data: [
        {
          icon: require('../assets/icons/gitee.png'),
          href: 'https://gitee.com/',
          text: 'Gitee',
          desc:
            'Gitee 为中国开发者提供了更好的本地化能力和服务，在促进中国开源发展上贡献巨大，非常期待 Gitee 更上层楼。',
        },
        {
          icon: require('../assets/icons/github.png'),
          href: 'https://github.com/',
          text: 'GitHub',
          desc:
            '数以百万计的开发人员和公司在github(世界上最大、最先进的开发平台)上构建、发布和维护他们的软件。',
        },
        {
          icon: require('../assets/icons/fugit.png'),
          href: 'https://gitee.com/docmirror/dev-sidecar',
          text: '辅助工具',
          desc: '常用于上GitHub,开发者的辅助工具,加速',
        },
      ],
    },
    {
      title: '工具下载',
      ref: 'box4',
      data: [
        {
          icon: require('../assets/icons/vscode.png'),
          href: 'https://code.visualstudio.com/',
          text: 'Vscode(编写代码)',
          desc:
            'Visual Studio Code(VS Code)是微软2015年推出的一个轻量但功能强大的源代码编辑器',
        },
        {
          icon: require('../assets/icons/HBuilderX.png'),
          href: 'https://www.dcloud.io/hbuilderx.html',
          text: 'HbuilderX(编写代码)',
          desc:
            'HBuilderX，简称HX，是轻如编辑器、强如IDE的合体版本它令我们爱不释手，希望你也会喜欢上这款免费工具',
        },
        {
          icon: require('../assets/icons/Sublime.png'),
          href: 'http://www.sublimetext.com/download',
          text: 'Sublime Text(编写代码)',
          desc:
            'Sublime Text 是一款流行的代码编辑器软件，也是HTML和散文先进的文本编辑器，可运行在Linux，Windows和Mac OS X。也是许多程序员喜欢使用的一款文本编辑器软件。',
        },
        {
          icon: require('../assets/icons/weixin.png'),
          href:
            'https://developers.weixin.qq.com/miniprogram/dev/devtools/download.html',
          text: '微信开发者工具(编写代码)',
          desc:
            '为了帮助开发者简单和高效地开发和调试微信小程序，我们在原有的公众号网页调试工具的基础上，推出了全新的 微信开发者工具',
        },
        {
          icon: require('../assets/icons/postman.png'),
          href: 'https://www.postman.com/',
          text: 'Postman测试接口',
          desc: '测试接口返回数据',
        },
        {
          icon: require('../assets/icons/apifox.png'),
          href:
            'https://www.apifox.cn/?utm_source=baidu_sem2&bd_vid=8233050266963410852',
          text: 'Apifox测试接口',
          desc: '测试接口返回数据',
        },
      ],
    },
    {
      title: '服务器购买',
      ref: 'box5',
      data: [
        {
          icon: require('../assets/icons/tengxunyun.png'),
          href: 'https://curl.qcloud.com/B3g2EypZ',
          text: '腾讯云服务器',
          desc: '腾讯云服务器',
        },
        {
          icon: require('../assets/icons/aliyun.png'),
          href:
            'https://www.aliyun.com/daily-act/ecs/activity_selection?userCode=6zy4ctx7',
          text: '阿里云服务器',
          desc: '阿里云服务器',
        },
      ],
    },
    {
      title: '面试题网站',
      ref: 'box6',
      data: [
        {
          icon: require('../assets/icons/niuke.png'),
          href: 'https://www.nowcoder.com/',
          text: '牛客网',
          desc: '各种程序员的面试题，需要自己搜索',
        },
        {
          icon: require('../assets/icons/juejin.png'),
          href: 'https://juejin.cn/',
          text: '掘金',
          desc: '一个社区，里面有人分享的面试题和技术文章',
        },
        {
          icon: require('../assets/icons/leetcode.png'),
          href: 'https://leetcode-cn.com/problemset/all/',
          text: '力扣题库',
          desc: '算法题，各种烧脑子的题目',
        },{
          icon: require('../assets/icons/baike.png'),
          href: 'https://interview.html5.wiki/',
          text: '前端面试百科',
          desc: '讲解各种技巧和常见的考点',
        },
      ],
    },
    {
      title: 'node常用',
      ref: 'box7',
      data: [
        {
          icon: require('../assets/icons/Expenses.png'),
          href: 'https://www.expressjs.com.cn/',
          text: 'Express',
          desc: '基于 Node.js 平台，快速、开放、极简的 Web 开发框架',
        },
        {
          icon: require('../assets/icons/nm_logo_200x136.png'),
          href: 'https://nodemailer.com/about/',
          text: 'node发邮箱',
          desc: 'node后台发邮箱的模板',
        },
        {
          icon: require('../assets/icons/brand_logo.png'),
          href:
            'https://www.sequelize.com.cn/core-concepts/getting-started',
          text: 'node连接数据库',
          desc: 'node连接数据库文档',
        },
        {
          icon: require('../assets/icons/uuid.png'),
          href: 'https://www.jianshu.com/p/0388e3d4bed4',
          text: 'node生成唯一id',
          desc: '生成唯一id',
        },
        {
          icon: require('../assets/icons/file_type_nodemon.png'),
          href: 'https://blog.csdn.net/qq_37774171/article/details/87824828',
          text: 'nodemon',
          desc: '每次修改node后台代码保存，自动重新启动了',
        },
      ],
    },  {
      title: '其他',
      ref: 'box8',
      data: [
        {
          icon: require('../assets/icons/erweima.png'),
          href: 'https://cli.im/',
          text: '草料二维码',
          desc: '可以把链接生成二维码',
        },
        {
          icon: require('../assets/icons/iconfont.png'),
          href:
            'https://www.iconfont.cn/home/index',
          text: '阿里图库',
          desc: '各种免费的小图标',
        },
        {
          icon: require('../assets/icons/yi.png'),
          href: 'https://easydoc.net/',
          text: '易文档',
          desc: '编写在的接口文档网站',
        },
        {
          icon: require('../assets/icons/tuchaung.png'),
          href: 'https://www.imgurl.org/',
          text: '图传(右键跳转，复制有问题)',
          desc: '将本地图片变成网络图片，右键跳转链接到该图片，',
        },
      ],
    },
    //个人文章和代码
    // 接单网站
    //个人作品
    //
    // 杂七杂八的网站    截图工具  Snipaste  
    //
  ]


