import Vue from 'vue'
import App from './App.vue'

import router from './router'

import videojs from "video.js";
import "video.js/dist/video-js.css";
Vue.prototype.$video = videojs;
// import 'lib-flexible/flexible'
import './assets/reset.css'
// import 'lib-flexible/flexible'
Vue.config.productionTip = false
// import { Button } from 'element-ui';
// Vue.use(Button)

// axios

import NProgress from 'nprogress' // nprogress插件 
import 'nprogress/nprogress.css' // nprogress样式

import axios from 'axios'
import VueAxios from 'vue-axios'

axios.interceptors.request.use(res=>{
  NProgress.start();
  return res
})
axios.interceptors.response.use(result=>{
  // console.log(666);
  NProgress.done();
  return result
})

Vue.use(VueAxios, axios)

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
