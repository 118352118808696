import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '*',
    redirect: {
      name: 'Home',
    },
  },
  {
    path: '/video',
    name:"Video",
    component: () => import(/* webpackChunkName: "about" */ '../views/Video.vue')
  },
]

const router = new VueRouter({
  mode:"history",
  base: process.env.BASE_URL,
  routes,
})

export default router
